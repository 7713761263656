//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters,mapActions } from 'vuex';
import { BranchPermissions } from '@/modules/branch/branch-permissions';
import BranchListCard from '@/modules/branch/components/branch-list-card.vue';
import BranchListTable from '@/modules/branch/components/branch-list-table.vue';
// import BranchFormModal from '@/modules/branch/components/branch-form-modal.vue';
import BranchFormModal from '@/modules/branch/components/branch-form-modal.vue';
// import { i18n } from '@/i18n';

export default {
  name: 'app-branch-list-page',

  components: {
    [BranchListCard.name]: BranchListCard,
    [BranchListTable.name]: BranchListTable,
    // [BranchFormModal.name]: BranchFormModal,
    [BranchFormModal.name]: BranchFormModal,
  },
  // async created() {
  //   console.log(this.currentUser);
  //   // debugger
  //   const companyId = this.currentUser.companyId
  //   await this.doFetch(companyId);
  // },
  data() {
    return {
      dialogVisible: false,
      modalVisible: false,
      branchs: [
        {
          id: '1',
          // photo: 'https://cdn.quasar.dev/img/parallax2.jpg',
          photo: 'https://cdn.quasar.dev/img/mountains.jpg',
          name: {
            en: 'Mekkah branch',
            ar: 'Mekkah branch',
          },
          city: {
            name: {
              en: 'El Madina, Saudi Arabia',
              ar: 'El Madina, Saudi Arabia',
            },
          },
          address: {
            en:
              'Al Fanateer District, Al Shati Street Chamber of Commerce Business Tower, Al Fanateer Dist. Jubail Industrial City.',
            ar:
              'حي الفناتير شارع الشاطئ غرفة التجارة برج الأعمال حى الفناتير. مدينة الجبيل الصناعية.',
          },
          guides: [
            {
              fullName: 'Ahmed Omar',
              avatar:
                'https://cdn.quasar.dev/img/avatar2.jpg',
            },
            {
              fullName: 'Nader Mostafa',
              avatar:
                'https://cdn.quasar.dev/img/avatar2.jpg',
            },
            {
              fullName: 'Ali Ahmed',
              avatar:
                'https://cdn.quasar.dev/img/avatar2.jpg',
            },
            {
              fullName: 'Ahmed Omar',
              avatar:
                'https://cdn.quasar.dev/img/avatar2.jpg',
            },
            {
              fullName: 'Nader Mostafa',
              avatar:
                'https://cdn.quasar.dev/img/avatar2.jpg',
            },
            {
              fullName: 'Ali Ahmed',
              avatar:
                'https://cdn.quasar.dev/img/avatar2.jpg',
            },
            {
              fullName: 'Ahmed Omar',
              avatar:
                'https://cdn.quasar.dev/img/avatar2.jpg',
            },
            {
              fullName: 'Nader Mostafa',
              avatar:
                'https://cdn.quasar.dev/img/avatar2.jpg',
            },
            {
              fullName: 'Ali Ahmed',
              avatar:
                'https://cdn.quasar.dev/img/avatar2.jpg',
            },
            {
              fullName: 'Ahmed Omar',
              avatar:
                'https://cdn.quasar.dev/img/avatar2.jpg',
            },
            {
              fullName: 'Nader Mostafa',
              avatar:
                'https://cdn.quasar.dev/img/avatar2.jpg',
            },
            {
              fullName: 'Ali Ahmed',
              avatar:
                'https://cdn.quasar.dev/img/avatar2.jpg',
            },
            {
              fullName: 'Ahmed Omar',
              avatar:
                'https://cdn.quasar.dev/img/avatar2.jpg',
            },
            {
              fullName: 'Nader Mostafa',
              avatar:
                'https://cdn.quasar.dev/img/avatar2.jpg',
            },
            {
              fullName: 'Ali Ahmed',
              avatar:
                'https://cdn.quasar.dev/img/avatar2.jpg',
            },
            {
              fullName: 'Ahmed Omar',
              avatar:
                'https://cdn.quasar.dev/img/avatar2.jpg',
            },
            {
              fullName: 'Nader Mostafa',
              avatar:
                'https://cdn.quasar.dev/img/avatar2.jpg',
            },
            {
              fullName: 'Ali Ahmed',
              avatar:
                'https://cdn.quasar.dev/img/avatar2.jpg',
            },
            {
              fullName: 'Ahmed Omar',
              avatar:
                'https://cdn.quasar.dev/img/avatar2.jpg',
            },
            {
              fullName: 'Nader Mostafa',
              avatar:
                'https://cdn.quasar.dev/img/avatar2.jpg',
            },
          ],
          createdBy: 'cef94fbed27e7e4e7fb33a97084d2c1f',
          updatedBy: 'cef94fbed27e7e4e7fb33a97084d2c1f',
          createdAt: '2021-02-08T20:59:08.103Z',
          updatedAt: '2021-02-08T20:59:08.103Z',
        },
      ],
      branches: [
        {
          id: '1',
          branchName: 'Jeddah Branch',
          branchCity: 'Jeddah',
          phoneNumber: '+966123456',
          createdAt: '22-4-2021',
        },
        {
          id: '2',
          branchName: 'Jeddah Branch',
          branchCity: 'Jeddah',
          phoneNumber: '+966123456',
          createdAt: '22-5-2021',
          action: '',
        },
        {
          id: '3',
          branchName: 'Jeddah Branch',
          branchCity: 'Jeddah',
          phoneNumber: '+966123456',
          createdAt: '22-6-2021',
          action: '',
        },
        {
          id: '4',
          branchName: 'Jeddah Branch',
          branchCity: 'Jeddah',
          phoneNumber: '+966123456',
          createdAt: '22-7-2021',
          action: '',
        },
        {
          id: '5',
          branchName: 'Jeddah Branch',
          branchCity: 'Jeddah',
          phoneNumber: '+966123456',
          createdAt: '22-8-2021',
          action: '',
        },
      ],
      success: false,
      modalSuccessVisible: false,
    };
  },
  computed: {
    ...mapGetters({
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      is_screen_xs: 'layout/is_screen_xs',
      currentLanguageCode: 'layout/currentLanguageCode',
      rows: 'branch/list/rows',
      count: 'branch/list/count',
    }),
    hasPermissionToCreate() {
      return new BranchPermissions(this.currentUser).create;
    },
  },
  methods: {
    ...mapActions({
      doFetch: 'branch/list/doFetch',
    }),
    i18n(key, args) {
      // return i18n(key, args);
      return this.$t(key, args);
    },
    onOpenModal() {
      this.modalVisible = true;
      this.dialogVisible = true;
    },
    onModalClose() {
      console.log('CLOSED');
      this.dialogVisible = false;
      setTimeout(() => {
        this.modalVisible = false;
        // if(operation == 'create'){
                // this.onModalSuccessClose()
              // }  
      }, 200);
    },
    onModalSuccessClose() {
      console.log('CLOSED');
      this.success = false;
      setTimeout(() => {
        this.modalSuccessVisible = false;
      }, 200);
      this.doFetch(this.currentUser.companyId)
    },
    branchSaved() {
      this.onModalClose();
      this.success = true;
      this.modalSuccessVisible = true;
    },
  },
};
