//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { UserModel } from '@/modules/auth/user-model';
import {mapGetters, mapActions } from 'vuex';
import Roles from '@/security/roles';
import BranchFormModal from '@/modules/branch/components/branch-form-modal.vue';
import moment from 'moment';
const { fields } = UserModel;
import i18n from '@/vueI18n'
export default {
  name: 'app-branch-list-table',
    components: {
    [BranchFormModal.name]: BranchFormModal,
  },
    props: {
  
  },

  data() {
    return {
      selectedId:'',
      record: null,
      dialogVisible:false ,
      modalVisible: false,
      Pagination: {
        rowsPerPage: 10
        
      },
      
      columns: [
        {
          name: 'name',
          align: 'center',
          label: this.i18n('tables.branch.branchName'),
          field: 'name',
        },        
        {
          name: 'address',
          align: 'center',
          label: this.i18n('tables.branch.branchCity'),
          field: 'address',
        },       
        //  {
        //   name: 'phoneNumber',
        //   align: 'center',
        //   label: i18n('tables.branch.phoneNumber'),
        //   field: 'phoneNumber',
        // },
        {
          name: 'createdAt',
          align: 'center',
          label: this.i18n('tables.branch.createdAt'),
          field: 'createdAt',
        },
        {
          name: 'action',
          align: 'center',
          label: this.i18n('tables.branch.action'),
          field: 'action',
        },



      ],

    };
  },

  // mounted hook call two function when page loading first is doFilter to diplay table upon to that filteration 
  // 

//   mounted() {
//     // console.log('tour guide')
//     this.doFilter();
//     this.doMountTable(this.$refs.table);
//   },

async mounted() {
    console.log(this.currentUser);
    // debugger
    const companyId = this.currentUser.companyId
    await this.doFetch(companyId);
  },





  computed: {
    ...mapGetters({
      rows: 'branch/list/rows',
      count: 'branch/list/count',
      loading: 'branch/list/loading',
    //   pagination: 'tourGuide/list/pagination',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      paginationLayout: 'layout/paginationLayout',
      is_screen_xs: 'layout/is_screen_xs'
    }),

    fields() {
      return fields;
    },
    language(){
      return i18n.locale
    }

  },
  watch:{
    rows(newval){
      console.log(newval);
    }
  },
  created() {
    console.log(this.rows);
    // console.log("rows" , this.values);
  },

  methods: {
    ...mapActions({
      doFetch: 'branch/list/doFetch',
    }),
    // ...mapActions({
    //   doChangeSort: 'tourGuide/list/doChangeSort',
    //   doChangePaginationCurrentPage:
    //     'tourGuide/list/doChangePaginationCurrentPage',
    //   doChangePaginationPageSize:
    //     'tourGuide/list/doChangePaginationPageSize',
    //   doMountTable: 'tourGuide/list/doMountTable',
    //   doFetch: 'tourGuide/list/doFetch',
    // }),
    // mapAction call to this functions from store { doFetch && doChangeSort && doChangePaginationCurrentPage && doChangePaginationPageSize && doMountTable }


  presenterMap(row, fieldName) {
      // const val = OfferModel.presenter(row, fieldName);
      // return val[this.language] ? val[this.language] : null     
      if(this.language === 'ar'){
        return row[fieldName]['ar']
      }
      return row[fieldName]['en']
    },

    presenterDate(row, fieldName) {
      // const val = OfferModel.presenter(row, fieldName)    
      if(this.language === 'ar'){
        return row[fieldName] ? moment(row[fieldName]).locale('ar').format("D MMM, YYYY") : 'ـــــــ';
      }
      return row[fieldName] ? moment(row[fieldName]).locale('en').format("D MMM, YYYY") : 'ـــــــ';
    },


    roleLabelOf(roleId) {
      return Roles.labelOf(roleId);
    },

    // presenter function display data from model to fields 

    presenter(row, fieldName) {
      return UserModel.presenter(row, fieldName);
    },
    onOpenModal() {
      this.modalVisible = true;
      this.dialogVisible = true;
    },
    onModalClose() {
      this.dialogVisible = false;
      setTimeout(() => {
        this.modalVisible = false;
      }, 200);
    },
    viewBranch(id) {
      console.log(id);
      return this.$router.push(`/branch/${id}`)
    },

    // doFilter function first check validation then clear it finally call doFetch and pass filter to it 

    async doFilter() {
      // try {
      //   await this.$refs.form.validate();
      //   this.$refs.form.clearValidate();
      // } catch (error) {
      //   return;
      // }
      const filter = {};
      return this.doFetch({
        filter,
      });
    },


  // i18n for localization 

    // i18n(code) {
    //   return i18n(code);
    // },
    i18n(key, args) {
      return this.$t(key, args);
    },
  },
};
